<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- filters Area -->
    <!-- ************************************************************************************ -->
    <MetricFilterBox
      :defaultFilterData="metrics.states"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetrics"
      :clearBtnShowen="metricsList.length > 0"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric)"
      >
        <template slot="filterBody">
          <component
            v-if="metric.key != 'orderby'"
            :is="metric.compName"
            v-model="filters[metric.key]"
            :state="filters.state"
            :meta="metric.meta"
            @change="filtersChanged(metric.key, $event)"
          ></component>
          <component
            v-else
            :is="metric.compName"
            v-model="orderby"
            @change="orderChanged"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <div class="u-display-flex u-justify-content--end u-bottom-margin--2x">
      <el-button
        size="mini"
        icon="el-icon-bottom"
        type="info"
        plain
        @click="exportReceiptsClicked"
      >
        {{ $t("GlobalExportFileButtonLabel") }}
      </el-button>

      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>
    <!-- ************************************************************************************ -->
    <!-- table Area -->
    <!-- ************************************************************************************ -->
    <div class="c-distributers-body">
      <el-table
        :data="receiptsList"
        @row-click="singleRowClicked"
        header-cell-class-name="table-header"
        style="width: 100%; border-spacing: 0 1em"
        row-class-name="table-row"
        @select="checkButtonReceiptClicked"
        @select-all="checkButtonReceiptClicked"
      >
        <el-table-column
          type="selection"
          width="50"
          :selectable="selectionDisabler"
        ></el-table-column>

        <el-table-column
          prop="colorCode"
          :label="$t('AuditPageTableColumnLabelColorCode')"
        >
          <template slot-scope="scope">
            <!-- <div style="
              display:flex;
              flex-direction:column;
              align-items:center;
              justify-content:center;
            "> -->
            <div
              :style="'background-color:' + scope.row.colorCode"
              style="width: 30px; height: 30px; border-radius: 15px"
            ></div>
            <!-- </div> -->
          </template>
        </el-table-column>
        <el-table-column :label="`Creation Date`" prop="createdAt">
          <template slot-scope="prop">
            <div>{{ prop.row.createdAt | moment("DD/MM/YYYY HH:mm:ss") }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('AuditPageTableColumnLabelTransactionId')"
          prop="transactionId"
        >
        </el-table-column>
        <el-table-column :label="`Receipt ID`" prop="id" />

        <el-table-column
          prop="orderId"
          :label="$t('AuditPageTableColumnLabelOrderNum')"
        >
        </el-table-column>
        <el-table-column
          :label="$t('AuditPageTableColumnLabelOrderStore')"
          min-width="100px"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.storeName }}
              <i
                @click.stop="copyText(scope.row.storeName)"
                class="el-icon-document-copy"
              ></i>
            </div>
            <!-- <div class="small-text">
              # {{ scope.row.userId }}
              <i
                @click.stop="copyText(scope.row.userId)"
                class="el-icon-document-copy"
              ></i>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('AuditPageTableColumnLabelWholesalerName')"
          prop="wholesalerName"
          min-width="100px"
        >
        </el-table-column>
        <el-table-column
          :label="$t('AuditPageTableColumnLabelArea')"
          prop="area"
          min-width="100px"
        >
        </el-table-column>
        <el-table-column :label="$t('AuditPageTableColumnLabelReceiptValue')">
          <template slot-scope="scope">
            <div>{{ scope.row.totalPrice.toFixed(2) }} EGP</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('AuditPageTableColumnLabelActualValue')">
          <template slot-scope="scope">
            <template>
              <div v-if="scope.row.actualValue">
                {{ scope.row.actualValue }} EGP
              </div>
              <div v-else>null</div>
              <div class="small-text">{{ scope.row.discrepancy }}</div>
            </template>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('AuditPageTableColumnLabelStatus')" prop="status" >
        </el-table-column>
        <el-table-column prop="state" label="Action" " >
          <template slot-scope="props">
            <div>
              <el-button type="success" size="mini"  @click="markCompleteClicked(props.row)" :disabled="false"
              >Mark Complete</el-button>
            </div>
          </template>
        </el-table-column> 
        <el-table-column prop="opertaions" :label="$t('AuditPageTableColumnLabelAction')" >
          <template slot-scope="props"  >
            <div v-on:click.stop>
              <el-popconfirm
                :confirm-button-text="$t('GlobalOkBtn')"
                :cancel-button-text="$t('GlobalCancelBtn')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('AuditPageTableColumnLabelMarkCompleteText')"
                @confirm="markCompleteClicked(props.row)"
              >
                <el-button slot="reference" type="success" size="mini"  :disabled="disabler(props.row)">
                  {{$t('AuditPageTableButtonLabelMarkComplete')}}
                </el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>-->
      </el-table>
      <!-- ************************* -->
      <!-- pagination -->
      <!-- ************************ -->
      <div class="u-display-flex u-justify-content--center u-top-padding--2x">
        <el-pagination
          :hide-on-single-page="false"
          background
          layout="prev, pager, next"
          :total="pagination.totalResultsCount"
          :current-page="paginationCurrentPage"
          :page-size="25"
          @current-change="pageChanged"
        />
      </div>
      <el-collapse-transition>
        <div
          class="bulk-action-button-holder"
          v-show="checkedReceiptsList.length > 0"
        >
          <div class="bulk-action-button-holder__parent">
            <el-button
              type="primary"
              size="default"
              @click="bulkReceiptsCompleteClicked"
            >
              {{ $t("AuditPageButtonLabelBulkComplete") }}
            </el-button>
          </div>
        </div>
      </el-collapse-transition>
      <el-dialog
        :title="$t('AuditPageItemsCardLabel')"
        :visible.sync="showReceiptDetailsCard"
      >
        <el-row :gutter="20" class="u-bottom-margin--2x">
          <el-col :span="4">{{
            $t("AuditPageItemsCardFieldReceiptValue")
          }}</el-col>
          <el-col :span="4"
            >{{
              chosenRowForPopups.totalPrice
                ? chosenRowForPopups.totalPrice.toFixed(2)
                : null
            }}
            {{ $t("GlobalEGP") }}</el-col
          >
          <el-col :span="4">{{
            $t("AuditPageItemsCardFieldActualValue")
          }}</el-col>
          <el-col :span="5">
            <!-- :disabled="disabler(chosenRowForPopups)" -->
            <el-input
              type="number"
              v-model="chosenRowForPopups.editedActualValue"
              size="medium"
            ></el-input>
          </el-col>
          <el-col
            :offset="1"
            :span="4"
            v-show="
              chosenRowForPopups.editedActualValue !==
              chosenRowForPopups.actualValue
            "
          >
            <el-button
              type="success"
              size="medium"
              @click="editActualValueClicked(chosenRowForPopups)"
            >
              {{ $t("AuditPageItemsCardButtonLabelEditActualValue") }}
            </el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">{{ $t("AuditPageItemsCardFieldNotes") }}</el-col>
          <el-col :span="20">
            <!-- :disabled="disabler(chosenRowForPopups)" -->
            <el-input
              v-model="chosenRowForPopups.editedNote"
              size="medium"
            ></el-input>
          </el-col>
        </el-row>
        <el-table :data="chosenRowForPopups.items">
          <!-- <el-table-column type="index" label="" /el-table-column> -->
          <el-table-column
            property="quantity"
            :label="$t('AuditPageItemsCardTableColumnQuantity')"
            width="100"
          ></el-table-column>
          <el-table-column
            property="product.name"
            :label="$t('AuditPageItemsCardTableColumnName')"
          ></el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { receipts } from "@/services";
import dropdownCategoriesSelect from "@/components/dropdownCategoriesSelect";
import metricbox from "@/components/transaction/metricbox";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import { receiptsMetrics } from "../../../components/transaction/metricboxComponents";
import { utils } from "../../../utils";
import { mapGetters } from "vuex";
import { transactions } from "@/services";

export default {
  name: "AuditTable",
  components: {
    dropdownCategoriesSelect,
    MetricFilterBox,
    metricbox,
    ...receiptsMetrics,
  },

  data() {
    return {
      filters: {},
      metricsList: [],
      metrics: {
        states: [
          {
            key: "regionId",
            label: "AuditPageFilterLabelRegionName",
            compName: "regionMetric",
          },
          {
            key: "areaId",
            label: "AuditPageFilterLabelAreas",
            compName: "areaMetric",
          },
          {
            key: "orderId",
            label: "AuditPageFilterLabelOrderId",
            compName: "orderIDMetric",
          },
          {
            key: "storeName",
            label: "AuditPageFilterLabelStoreName",
            compName: "stringMetric",
          },
          {
            key: "storeId",
            label: "AuditPageFilterLabelStoreId",
            compName: "stringMetric",
          },
          {
            key: "providerName",
            label: "AuditPageFilterLabelProviderName",
            compName: "stringMetric",
          },
          {
            key: "providerId",
            label: "AuditPageFilterLabelProviderId",
            compName: "stringMetric",
          },
          {
            key: "colorCode",
            label: "AuditPageFilterLabelColorCode",
            compName: "colorCodeMetric",
          },
          {
            key: "startDate",
            label: "AuditPageFilterLabelStartDate",
            compName: "startDateMetric",
          },
          {
            key: "endDate",
            label: "AuditPageFilterLabelEndDate",
            compName: "endDateMetric",
          },
          {
            key: "id",
            label: "TransactionPageReceiptIdFilterLabel",
            compName: "stringMetric",
          },
        ],
      },
      orderby: "",
      pagination: {},
      receiptsList: [],
      checkedReceiptsList: [],
      showReceiptDetailsCard: false,
      chosenRowForPopups: {},
    };
  },
  async created() {
    await this.setInitFiltersAndFetchData();
  },
  props: {
    display: {
      default: "pending",
    },
  },
  computed: {
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? currentPageQuery : 1;
    },
    ...mapGetters({
      lookups: "getAllLookups",
      filtersData: "getTransactionFilters",
    }),
  },
  methods: {
    copyText(text) {
      utils.copyText(text);
    },
    //**************************************************************************************** */
    //filters Area
    //**************************************************************************************** */

    clearFilters() {
      this.filters = {};
      this.orderby = "";
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter")) || {};
      sessionFilter.receipts = {};
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("receiptsMetrics", JSON.stringify([]));
      this.getReceipts(1, this.filters);
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      this.filtersChanged(metric.key, undefined);
      localStorage.setItem("receiptsMetrics", JSON.stringify(this.metricsList));
    },
    saveMetrics() {
      localStorage.setItem("receiptsMetrics", JSON.stringify(this.metricsList));
    },

    async getReceipts(page, filters) {
      //hook
      try {
        this.$loading();
        if (this.display) {
          const displayTabString = this.display;
          const pendingReviewFilterValue =
            displayTabString == "pending" ? 1 : 0;
          filters.pending_review = pendingReviewFilterValue;
        }
        const response = await receipts.getAllReceipts(page, { ...filters });
        const responseData = response.data;

        let fetchedReceipts = responseData.receipts.map((singleReceipt) => {
          singleReceipt = {
            ...singleReceipt,
            // colorCode:"#FCCD13"
          };
          return singleReceipt;
        });
        let coloredReceipts = this.colorizeReceipts(fetchedReceipts);
        this.receiptsList = coloredReceipts;

        const paginationObject = {
          totalPages: responseData.pages,
          totalResultsCount: responseData.count,
          nextPage: responseData.next,
        };
        this.pagination = paginationObject;
        this.checkedReceiptsList = [];
      } catch (error) {
        console.log("error :>> ", error);
        this.$loading().close();
      }
      this.$loading().close();
    },
    colorizeReceipts(receipts) {
      const colorized = receipts.map((singleReceipt) => {
        if (singleReceipt.discrepancy !== null) {
          const currentDisc = singleReceipt.discrepancy;
          if (currentDisc >= 2 || currentDisc <= 0.5) {
            singleReceipt.colorCode = "red";
            return singleReceipt;
          }
          if (
            (1.1 < currentDisc && currentDisc < 2) ||
            (0.5 < currentDisc && currentDisc <= 0.9)
          ) {
            singleReceipt.colorCode = "#FCCD13";
            return singleReceipt;
          }
          if (0.9 < currentDisc && currentDisc <= 1.1) {
            singleReceipt.colorCode = "green";
            return singleReceipt;
          }
        }
        singleReceipt.colorCode = "#EAEAEA";
        return singleReceipt;
      });
      return colorized;
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    orderChanged(value) {
      this.orderby = value;
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.receipts = {
        filters: { ...this.filters },
        orderby: this.orderby,
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.getReceipts(1, { ...this.filters });
      this.$router.push({
        query: {
          page: 1,
        },
      });
    },
    filtersChanged(key, value) {
      if (typeof value !== "number" && _.isEmpty(value)) {
        delete this.filters[key];
      }
      if (value && value.length && value.length == 0) {
        delete this.filters[key];
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.receipts = {
        filters: this.filters,
        orderby: this.orderby,
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.saveMetrics();
      if (this.$route.fullPath !== "/audit?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getReceipts(1, this.filters);
    },

    async markCompleteClicked(obj) {
      this.chosenRowForPopups = obj;
      // if(obj.status!=="complete"){
      const apiObject = { pending_review: 0 };
      this.$loading();
      try {
        const response = await receipts.updateReceiptData(obj.id, apiObject);
        this.$loading().close();
        this.chosenRowForPopups = {};
        if (response) {
          this.popupMessageWrapper(
            this.$t("AuditPageMarkCompleteSuccessMessage"),
            "success",
            1500
          );
          setTimeout(async () => {
            await this.getReceipts(this.currentPage, this.filters);
          }, 1500);
        }
      } catch (error) {
        console.log("error :>> ", error);
        this.popupMessageWrapper(this.$t("GlobalErrorMessage"), "error", 1500);
        this.$loading();
      }
      // }
    },
    singleRowClicked(rowData) {
      let toBeDisplayedObject = JSON.parse(JSON.stringify(rowData));
      toBeDisplayedObject.editedActualValue = toBeDisplayedObject.actualValue;
      toBeDisplayedObject.editedNote = toBeDisplayedObject.OPS_notes;
      this.chosenRowForPopups = toBeDisplayedObject;
      this.showReceiptDetailsCard = true;
    },
    checkButtonReceiptClicked(value) {
      this.checkedReceiptsList = value;
    },
    async bulkReceiptsCompleteClicked() {
      let bulkApiObject = this.checkedReceiptsList.map((singleItem) => {
        let singleObject = {
          id: singleItem.id,
          // status: "complete",
          pending_review: 0,
        };
        return singleObject;
      });
      this.$loading();
      try {
        const response = await receipts.bulkUpdateReceipts(bulkApiObject);
        this.$loading().close();
        if (response) {
          this.checkedReceiptsList = [];
          this.popupMessageWrapper(
            this.$t("AuditPageVulkCompleteSuccessMessage"),
            "success",
            1500
          );
          setTimeout(async () => {
            await this.getReceipts(this.currentPage, this.filters);
          }, 1500);
        }
      } catch (error) {
        console.log("error :>> ", error);
        this.$loading().close();
        this.popupMessageWrapper(this.$t("GlobalErrorMessage"), "error", 1500);
      }
    },
    async editActualValueClicked(currentReceipt) {
      let editApiObject = {
        actualAmount: parseFloat(currentReceipt.editedActualValue),
        status: "complete",
        pending_review: 0,
        // OPS_notes: currentReceipt.editedNote,
      };
      if (currentReceipt.editedNote && currentReceipt.editedNote.length)
        editApiObject.OPS_notes = currentReceipt.editedNote;
      try {
        const response = await transactions.updateReceiptActualAmount(
          currentReceipt.id,
          editApiObject
        );
        if (response) {
          this.showReceiptDetailsCard = false;
          this.chosenRowForPopups = {};
          this.popupMessageWrapper(
            this.$t("AuditPageActualValueUpdateSuccessMessage"),
            "success",
            1500
          );
          setTimeout(async () => {
            await this.getReceipts(this.currentPage, this.filters);
          }, 1500);
        }
      } catch (error) {
        console.log("error :>> ", error);
        this.popupMessageWrapper(this.$t("GlobalErrorMessage"), "error", 1500);
      }
    },
    async setInitFiltersAndFetchData() {
      let sessionFiltersObj = JSON.parse(sessionStorage.getItem("filter"));
      let filtersSession = null;
      if (sessionFiltersObj && sessionFiltersObj.receipts) {
        filtersSession = sessionFiltersObj.receipts;
      }
      if (filtersSession && filtersSession.filters)
        this.filters = filtersSession.filters;
      if (filtersSession && filtersSession.orderby)
        this.orderby = filtersSession.orderby;
      const metricsSession = JSON.parse(
        localStorage.getItem("receiptsMetrics")
      );
      if (metricsSession) {
        this.metricsList = metricsSession;
      }
      const startDateMetricSearch = this.metricsList.filter(
        (item) => item.compName == "startDateMetric"
      );
      if (startDateMetricSearch.length == 0) {
        this.filters = { ...this.filters, startDate: "2022-4-26" };
        this.metricsList = [
          ...this.metricsList,
          {
            key: "startDate",
            label: "AuditPageFilterLabelStartDate",
            compName: "startDateMetric",
          },
        ];
        this.saveMetrics();
      }

      await this.getReceipts(this.currentPage, this.filters);
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    disabler(singleItem) {
      const pendingReviewValue = singleItem.pending_review;
      // return !(['pending', 'inprogress'].includes(status))
      return pendingReviewValue !== 1;
    },
    selectionDisabler(row) {
      return row.pending_review == 1;
    },
    async exportReceiptsClicked() {
      try {
        const response = await receipts.exportReceiptsSheet(this.filters);
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        utils.downloadBlob(blob, "receipts", "xlsx");
      } catch (errorObject) {
        console.log("exportReceiptserrorObject", errorObject);
        const errorMessageExists =
          errorObject.response &&
          errorObject.response.data &&
          errorObject.response.data.error;
        let finalErrorMessage = this.$t(
          "RetailerWalletsPageWalletDefaultErrorMessage"
        );
        if (errorMessageExists) {
          finalErrorMessage = errorObject.response.data.error;
        }
        this.popupMessageWrapper(finalErrorMessage, "error", 1500);
      }
    },
  },
  watch: {
    $route(to) {
      this.getReceipts(to.query.page, this.filters);
    },
    display() {
      // this.filters.status=to
      if (this.$route.fullPath !== "/audit?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getReceipts(1, this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/wrench-kit/settings/_settings.global.scss";
$primary-color: #405793;
::v-deep .table-header {
  color: #8ea6c1;
}
::v-deep .table-row {
  font-weight: 900;
  color: #405793;
  i {
    color: #fccd13;
    transition: all 0.25s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }
  cursor: pointer;
  background-color: #ffffff;
  margin-bottom: 1rem;
  border-radius: 1px;
  box-shadow: 0 0 32px 0 rgba(12, 10, 61, 0.04);
  .small-text {
    font-weight: 500;
    color: #abb5d0;
  }
}

.bulk-action-button-holder {
  position: absolute;
  top: 5vh;
  right: 5vw;
  background-color: white;
  border: 1px solid $primary-color;
  border-radius: 5px;
  width: 250px;
  height: 150px;
  &__parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
