<template>
    <div>
        <el-row type="flex" justify="center" class="u-bottom-margin--2x">
            <el-col :span="12">
                <el-tabs v-model="activeName" @tab-click="tabClicked" stretch>
                    <el-tab-pane 
                        v-for="item in navigationItems" 
                        :label="item.tabLabel" 
                        :name="item.tabName"
                        :key="item.tabLabel"
                    ></el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <AuditTable :display="activeName"/>
    </div>
</template>

<script>
import AuditTable from "./AuditTable.vue"
export default {
    name:"AuditPage",
    components:{
        AuditTable
    },
    data(){
        return {
            activeName: 'pending',
            navigationItems:[
                {
                    tabLabel:this.$t('AuditPageTabOptionLabelPendingReview'),
                    tabName:"pending"
                },
                {
                    tabLabel:this.$t('AuditPageTabOptionLabelCompleted'),
                    tabName:"complete"
                }
            ]
        }
    },
    created(){
        this.chosenTabHandler();
    },
    methods:{
        chosenTabHandler(){
            const currentChosenTab = sessionStorage.getItem('chosenAuditTab')
            if(currentChosenTab){
                this.activeName = currentChosenTab
            }else{
                const defaultTabName = 'pending'
                this.activeName = defaultTabName
                this.storeActiveTab(defaultTabName)
            }
        },
        tabClicked(eventObject){
            this.storeActiveTab(eventObject.name)
        },
        storeActiveTab(value){
            sessionStorage.setItem('chosenAuditTab',value)
        }
    }
}
</script>

<style>

</style>